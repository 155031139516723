import "../styles/index.scss";

import Siema from "siema";
// import * as Shuffle from 'shufflejs';

import mixitup from "mixitup";
import GLightbox from "glightbox";

// console.log('webpack starterkit');
import "../scripts/menu";

import "../scripts/accordion";
import "../scripts/scroll";

function onInitCallback() {
  console.log("Siema initialised bro :)");
}

function onChangeCallback() {
  console.log(`The index of current slide is: ${this.currentSlide}`);
}

const mySiema = new Siema({
  selector: ".siema",
  duration: 200,
  easing: "ease-out",
  perPage: 1,
  startIndex: 0,
  draggable: true,
  multipleDrag: true,
  onInit: onInitCallback,
  onChange: onChangeCallback
});

const prev = document.querySelector(".prev");
const next = document.querySelector(".next");

prev.addEventListener("click", () => mySiema.prev());
next.addEventListener("click", () => mySiema.next());

const lightbox = GLightbox({
  touchNavigation: true,
  loop: true,
  autoplayVideos: true,
  moreLength: false,
  onOpen: () => {
    console.log("Lightbox opened");
  },
  beforeSlideLoad: (slide, data) => {
    // Need to execute a script in the slide?
    // You can do that here...
  }
});
var containerEl = document.querySelector(".portfoliolist");

var mixer = mixitup(containerEl, {
  selectors: {
    target: ".portfolio"
  },
  animation: {
    duration: 300
  },
  load: {
    filter: ".all"
  }
});
mixer.filter(".all");

var httpRequest = new XMLHttpRequest();
var form = document.getElementById("btnEnviar");
var nombre = document.getElementById("nombre");
var email = document.getElementById("email");
var tel = document.getElementById("telefono");
var mensaje = document.getElementById("mensaje");
var lbl = document.getElementById("lblResultado");
form.addEventListener("click", function(e){
  e.preventDefault();
  console.log("sending info");
  if(nombre.value == ""){
    lbl.innerHTML = "Se require el nombre";
  } else if (email.value == "") {
    lbl.innerText = "Se requiere el email";
  } else if( tel.value == ""){
    lbl.innerText = "Se requiere el teléfono";
  } else if (mensaje.value == ""){
    lbl.innerText = "Se requiere ingresar un mensaje";
  } else {
    lbl.innerText = "Enviando los datos, por favor espere...";
    

    httpRequest.open("POST", 'sendMail.php');
    httpRequest.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    httpRequest.addEventListener("progress", updateProgress);
    httpRequest.addEventListener("load", transferComplete);
    httpRequest.addEventListener("error", transferFailed);
    if (httpRequest.readyState === XMLHttpRequest.DONE) {
      if (httpRequest.status === 200) {
        lbl.innerText=httpRequest.responseText;
      } else {
        lbl.innerText = 'No pudimos enviar el formulario';
      }
    }
    httpRequest.send("nombre="+nombre.value+"&email="+email.value+"&tel="+tel.value+"&empresa="+empresa.value+"&mensaje="+mensaje.value);
  }
}, false);

function updateProgress (oEvent) {
  if (oEvent.lengthComputable) {
    var percentComplete = oEvent.loaded / oEvent.total * 100;
    console.log(percentComplete);
  } else {
    // Unable to compute progress information since the total size is unknown
  }
}

function transferComplete(evt) {
  lbl.innerText ="correo enviado";
  console.log(httpRequest.responseText);
}

function transferFailed(evt) {
  console.log("Ocurrio un error de comunicación");
}

function transferCanceled(evt) {
  console.log("The transfer has been canceled by the user.");
}