// for IE10, .getElementsByClassName()[0];
let toggle = document.querySelector(".toggle");
let topNav = document.querySelector(".nav--top");

let overlay = document.querySelector(".overlay");

// Listen for click event on toggle var
toggle.addEventListener('click', function() {
  console.log("toggle clicked");
  // toggle class "active" on topNav var
  topNav.classList.toggle("active");
  overlay.classList.toggle("sera");

}, false);


overlay.addEventListener('click', function() {
  console.log("toggle clicked");
  // toggle class "active" on topNav var
  topNav.classList.toggle("active");
  overlay.classList.remove("sera");


}, false);


